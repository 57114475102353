<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Blacklist a customer') }}</h3>
            <p class="c-muted m-b-5">{{ trans('Enter an IP address or an email address to prevent any customer matching it from being able to purchase your products. This is useful to ban serial refunders or fraudulent customers.') }}</p>
        </div>

        <div class="row row-collapse row-collapse-indent">
            <div class="columns">
                <div class="form-group" v-bind:class="{'has-error': errors.has('type')}">
                    <label>
                        {{ trans('Type') }}
                        <select v-model="record.type">
                            <option value="email">{{ trans('Email Address') }}</option>
                            <option value="ip">{{ trans('IP Address') }}</option>
                        </select>
                    </label>
                </div>
                <div v-if="errors.has('type')" class="form-help">{{ errors.get('type') }}</div>

                <div class="form-group" v-bind:class="{'has-error': errors.has('value')}">
                    <label>
                        {{ valueLabel }}
                        <input type="text" v-model="record.value" required>
                    </label>
                </div>
                <div v-if="errors.has('value')" class="form-help">{{ errors.get('value') }}</div>
            </div>
        </div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="addRecord" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Blacklist Customer') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';
import { blacklistStoreUrl } from '../../routes';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            record: {
                type: 'email',
                value: '',
            },
            show: false,
            loading: false,
            errors: new Errors(),
        }
    },
    computed: {
        valueLabel() {
            return this.record.type === 'email'
                ? this.trans('Enter Email Address')
                : this.trans('Enter IP Address');
        },
    },
    methods: {
        addRecord() {
            this.loading = true;

            window.axios.post(blacklistStoreUrl(), this.record).then((response) => {
                this.show = false;
                EventBus.$emit('blacklist:added', response.data.data);
                this.reset();
            }).catch((error) => {
                console.warn(error);
                this.loading = false;
                this.errors.record(error.response.data);
            });
        },
        reset() {
            this.record = {
                type: 'email',
                value: '',
            }

            this.loading = false;
            this.errors.clear();
        },
        close() {
            this.reset();
            this.show = false;
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-blacklist', () => {
            $vm.reset();
            $vm.show = true;
        });

        $vm.$on('close', () => {
            $vm.close();
        });
    },
}
</script>