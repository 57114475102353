<template>
    <modal v-bind:show.sync="show" ref="modal" size="medium">
        <div slot="header"></div>

        <div class="row">
            <div class="column">
                <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('To import more members, upgrade your plan') }}</h4>
                <div class="alert p-3 m-b-3 m-t-4 c-muted br br-c--fade c-accent-bg-light">
                    <div class="row row-collapse">
                        <span class="i-container i-filled-theme">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon inject-svg i-size-small m-r-2"><path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1h-2v-1c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C9.9 7.6 10.9 7 12 7c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" class="icon"></path></svg>
                        </span>
                        <div class="column">
                            <p class="m-0">{{ trans(':plan plan is limited to :limit members', {'plan': capitalizedPlan, 'limit': limits.members}) }}</p>
                        </div>
                    </div>
                </div>

                <p v-if="isAdmin" class="f-s-5 c-dark m-b-3">{{ trans('You have reached your limit for the number of members included in your current Kourses plan. Contact your site owner.') }}</p>
                <p v-else class="f-s-5 c-dark m-b-3">{{ trans('You have reached your limit for the number of members included in your current Kourses plan. Please click "Upgrade Plan" below to view plan upgrades available to you.') }}</p>
            </div>
        </div>
        <div v-if=" ! isAdmin" class="row m-t-4">
            <div class="small-12 columns">
                <div class="row row-center">
                    <div class="column medium-9 small-8 xsmall-12">
                        <a class="btn btn-theme btn--block open-modal m-b-4" href="/goto/stripe?flow=subscription_update" target="_blank">{{ trans('Upgrade Plan') }}</a>
                        <button type="button" v-on:click="close" class="btn btn-outline modal-close-external btn--block f-w-regular">{{ trans("Don't upgrade yet") }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer"></div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Modal from '../modals/Modal.vue';

export default {
    components: {
        Modal,
    },
    props: {
        plan: {
            type: String,
            required: true,
        },
        limits: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            capitalizedPlan: this.plan.charAt(0).toUpperCase() + this.plan.slice(1),
            isAdmin: this.user.account_type === 'admin',
        }
    },
    methods: {
        close() {
            this.show = false;
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:add-member', () => {
            $vm.show = true;
        });
    },
}
</script>